<template>
  <div id="main-wrapper" class="inner-pages why-page reg-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>Regulations and Licenses</h2>
        <p>
          We have multiple registrations and regulations around the globe. Full
          transparency about our registration details means more reliability,
          security, and peace of mind for our clients!
        </p>
      </div>
    </div>
    <div class="section why-bel reg-sec">
      <div class="wrapper">
        <div class="content-sec wow fadeIn">
          <div class="flag-holder">
            <img src="assets/images/greece.webp" alt="AAAFx" title="AAAFx" width="25" height="17" class="fluid-img" />
          </div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>Hellenic Capital Market Commission (HCMC), Greece</h5>
              <p>
                <strong
                  >Triple A Experts Investment Services Single Member SA is
                  authorized and regulated in European Union by the Hellenic
                  Capital Market Commission. (id:2/540/17.2.2010) Tax
                  Identification Number: 998281207.</strong
                >
              </p>
              <div class="btn-holder">
                <a href="http://www.hcmc.gr/en_US/web/portal/orgcmc/viewinvestmentfirms?auid=2fe" target="_blank" class="btn btn-blue">View License</a>
              </div>
            </div>
          </div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>About HCMC</h5>
              <p>
                The Hellenic Capital Market Commission (HCMC) was established as
                a legal entity in Greece for surveillance of the proper
                application of capital market legislation. The HCMC is a Member
                of the European Securities and Markets Authority (ESMA) and
                International Organization of Securities Commissions (IOSCO).
              </p>
            </div>
          </div>
          <div class="flag-holder"></div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>About MiFID II</h5>
              <p>
                The European Parliament and Council adopted Directive
                2014/65/EU, dated 15 May 2014, on Markets in Financial
                Instruments and Amending Directive 2002/92/EC and Directive
                2011/61/EU (recast) (MiFID II), which came into effect on
                03/01/2018. Its aim is to regulate the markets of financial
                instruments for the provision of investment services and
                activities within the European Economic Area (EEA). MiFID was
                incorporated to the Greek legislation Under Law No 4514/2018.<br /><br />
                The main objectives of the Directive are to increase efficiency,
                enhance financial transparency, increase competition, and offer
                greater consumer protection in investment services.
              </p>
            </div>
          </div>
          <!-- <div class="content-holder">
						<div class="title-holder">
							<h5>About MiFID II</h5>
							<p>The European Parliament and Council adopted Directive 2014/65/EU, dated 15 May 2014, on Markets in Financial Instruments and Amending Directive 2002/92/EC and Directive 2011/61/EU (recast) (MiFID II), which came into effect on 03/01/2018. Its aim is to regulate the markets of financial instruments for the provision of investment services and activities within the European Economic Area (EEA). MiFID was incorporated to the Greek legislation Under Law No 3606/2007.<br><br>
							The main objectives of the Directive are to increase efficiency, enhance financial transparency, increase competition, and offer greater consumer protection in investment services.</p>
						</div>
					</div> -->
          <div class="flag-holder"></div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>About GDPR</h5>
              <p>
                The General Data Protection Regulation (GDPR) is considered to
                be one of the toughest privacy laws in the world. It was drafted
                and passed by the European Union (EU) and put into effect on May
                25, 2018. It lays down a legal framework that sets guidelines
                for the collection and free movement of personal data of
                individuals within the European Union (EU). “AAAFx” and its
                affiliated companies, including its subsidiaries, branches,
                representative offices, directors, officers and employee take
                every reasonable step to comply with GDPR.
              </p>
            </div>
          </div>
          <div class="flag-holder"></div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>About Investor Compensation Fund</h5>
              <p>
                AAAFx investors are protected by the Greek Guarantee Fund
                according to the European Directive 93/22/EEC. In the event of
                an Investment Firm's failure to fulfil its obligations, the
                Greek Guarantee Fund will act in order to compensate the
                investors up to €30,000 per person. The Greek Guarantee Fund is
                financed by the ATHEX (Athens Exchange) Members, and it is
                supervised by the HCMC.
              </p>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="title-holder wow fadeIn">
            <h3>Group Regulations</h3>
          </div>
          <div class="content-sec wow fadeIn">
            <div class="flag-holder">
              <img src="assets/images/sa.webp" alt="AAAFx" title="AAAFx" width="25" height="17" class="fluid-img" />
            </div>
            <div class="content-holder">
              <div class="title-holder">
                <h5>Financial Sector Conduct Authority (FSCA), South Africa</h5>
                <p>
                  Sikhula Venture Capital (Pty) Ltd. is authorized and regulated
                  in South African Financial Sector Conduct Authority (FSCA).
                  (Licence Number: 2017/315029/07).
                </p>
                <div class="btn-holder">
                  <a href="https://www.fsca.co.za/Fais/Search_FSP.htm" target="_blank" class="btn btn-blue">View License</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="title-holder wow fadeIn">
            <h3>Registrations in other Jurisdictions</h3>
          </div>
          <!-- <div class="content-sec wow fadeIn">
            <div class="flag-holder">
              <img src="assets/images/vincent.webp" alt="AAAFx" title="AAAFx" width="25" height="16" class="fluid-img" />
            </div>
            <div class="content-holder">
              <div class="title-holder">
                <h5>St. Vincent and the Grenadines</h5>
                <p>
                  Alpha Global Ltd is registered in St. Vincent and the
                  Grenadines. (Reg. No. number 25849 BC 2020 of the Financial
                  Services Authority)
                </p>
                <div class="btn-holder">
                  <a href="#" class="btn btn-blue">View Certificate</a>
                </div>
              </div>
            </div>
          </div> -->

          <div class="content-sec wow fadeIn">
            <div
              class="juris-sec"
              v-for="registration in registrations"
              :key="registration.country"
            >
              <div class="flag-holder">
                <img
                  :src="
                    'assets/images/country_images/' +
                    registration.country.toLowerCase() +
                    '.webp'
                  "
                   alt="AAAFx" title="AAAFx" width="25" height="25" class="fluid-img"
                />
              </div>
              <div class="content-holder">
                <h5>{{ registration.country }}</h5>
                <a :href="registration.link" target="_blank" rel="noopener">{{
                  registration.label
                }}</a>
              </div>
            </div>
          </div>

          <!-- <div class="content-sec wow fadeIn">
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/france.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Austria</h5>
									<p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/germany.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Germany</h5>
									<p>lorem quis bibendum auctor, nisi elit consequat ipsum</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/hungary.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Hungary</h5>
									<p>Nec sagittis sem nibh id elit Duis sed odio sit amet nibh</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/italy.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Italy</h5>
									<p>Vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit </p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/norway.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Norway</h5>
									<p>Nam nec tellus a odio tincidunt auctor a ornare odio</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/france.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>France</h5>
									<p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/germany.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Germany</h5>
									<p>lorem quis bibendum auctor, nisi elit consequat ipsum</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/hungary.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Hungary</h5>
									<p>Nec sagittis sem nibh id elit Duis sed odio sit amet nibh</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/italy.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Italy</h5>
									<p>Vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit </p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/norway.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Norway</h5>
									<p>Nam nec tellus a odio tincidunt auctor a ornare odio</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/france.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>France</h5>
									<p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/germany.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Germany</h5>
									<p>lorem quis bibendum auctor, nisi elit consequat ipsum</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/hungary.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Hungary</h5>
									<p>Nec sagittis sem nibh id elit Duis sed odio sit amet nibh</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/italy.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Italy</h5>
									<p>Vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit </p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/norway.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Norway</h5>
									<p>Nam nec tellus a odio tincidunt auctor a ornare odio</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/france.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>France</h5>
									<p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/germany.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Germany</h5>
									<p>lorem quis bibendum auctor, nisi elit consequat ipsum</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/hungary.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Hungary</h5>
									<p>Nec sagittis sem nibh id elit Duis sed odio sit amet nibh</p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/italy.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Italy</h5>
									<p>Vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit </p>
							</div>
						</div>
						<div class="juris-sec">
							<div class="flag-holder">
								<img src="assets/images/norway.webp" alt="">
							</div>
							<div class="content-holder">
									<h5>Norway</h5>
									<p>Nam nec tellus a odio tincidunt auctor a ornare odio</p>
							</div>
						</div>

					</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      registrations: [
        {
          country: "Austria",
          label: "Finanzmarktaufsicht (FMA)",
          link: "https://www.fsma.be/en",
        },
        // {
        //   country: "Belgium",
        //   label: "Financial Services and Markets Authority (FSMA)",
        //   link: "https://www.fsma.be/en",
        // },
        {
          country: "Bulgaria",
          label: "Комисията за финансов надзор (FSC)",
          link: "https://www.fsc.bg/en/",
        },
        {
          country: "Croatia",
          label: "Hrvatska agencija za nadzor financijskih usluga (HANFA)",
          link: "https://www.hanfa.hr/en/",
        },
        {
          country: "Republic of Cyprus",
          label: "Cyprus Securities and Exchange Commission (CySec)",
          link: "https://www.cysec.gov.cy/en-GB/entities/investment-firms/member-states/Cross-Border/45356/",
        },
        {
          country: "Czech Republic",
          label: "Ceská národní banka (CNB)",
          link: "https://www.cnb.cz/en/",
        },
        {
          country: "Denmark",
          label: "Finanstilsynet (DFSA)",
          link: "https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn-en.html?v=E8C9DEE3-3B8A-E611-810C-00505680286D",
        },
        {
          country: "Estonia",
          label: "Finantsinspektsioon (FSA)",
          link: "https://www.fi.ee/en/investment-market/investment-firms-0/investment-market/providers-cross-border-investment-services/triple-experts-investment-services-sa-former-triple-experts-investment-services-single-member-sa",
        },
        {
          country: "Finland",
          label: "Finanssivalvonta (FSA)",
          link: "https://www.finanssivalvonta.fi/en/",
        },
        // {
        //   country: "France",
        //   label:
        //     "Autorité de Contrôle Prudentiel et de Résolution (ACPR)-51215",
        //   link: "https://www.amf-france.org/en",
        // },
        {
          country: "Germany",
          label:
            "Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) - 10128185 ",
          link: "https://www.bafin.de/EN/Homepage/homepage_node.html",
        },
        {
          country: "Hungary",
          label: "Magyar Nemzeti Bank (MNB)",
          link: "https://www.mnb.hu/web/en",
        },
        {
          country: "Ireland",
          label: "Central Bank of Ireland",
          link: "http://registers.centralbank.ie/FirmSearchResultsPage.aspx?searchEntity=Institution&searchType=Name&searchText=Triple+A+Experts&registers=32%2C33%2C58%2C59&firmType=InvestmentFirms",
        },
        {
          country: "Italy",
          label:
            "Commissione Nazionale per le Società e la Borsa (CONSOB) - 3422",
          link: "https://www.consob.it/web/consob-and-its-activities/investment-firms-without-branches#_48_INSTANCE_gV4ErCDseEwW_%3Dhttps%253A%252F%252Fwww.consob.it%252Fdocuments%252F46180%252F46181%252FCOM_115_EN.html%252F08057d62-f5ce-4a14-ac30-a1ca9c570efe%253F",
        },
        {
          country: "Latvia",
          label: "Finanšu un kapitala tirgus komisija (FKTK)",
          link: "https://www.fktk.lv/en/market/investment-service-providers/service-providers-from-the-eea/freedom-to-provide-services/triple-a-experts-investment-services-s-a/",
        },
        {
          country: "Lithuania",
          label: "Lietuvos Bankas (LB)",
          link: "https://www.lb.lt/en/",
        },
        {
          country: "Luxembourg",
          label: "Commission de Surveillance du Secteur Financier (CSSF)",
          link: "https://www.cssf.lu/en/",
        },
        {
          country: "Malta",
          label: "Malta Financial Services Authority (MFSA)",
          link: "https://www.mfsa.mt/financial-services-register/",
        },
        {
          country: "Netherlands",
          label: "Autoriteit Financiële Markten (AFM)",
          link: "https://www.afm.nl/en/professionals/registers/vergunningenregisters/beleggingsondernemingen/details?id=61147C5B-B5A7-E411-BA3E-005056BE011C",
        },
        {
          country: "Poland",
          label: "Komisji Nadzoru Finansowego (KNF)",
          link: "https://www.knf.gov.pl/en/CONSUMERS/Information_for_the_financial_market_consumers/Entities_search#3126881",
        },
        {
          country: "Portugal",
          label: "Comissão do Mercado de Valores Mobiliários (CMVM)",
          link: "https://www.cmvm.pt/en/pages/homepage.aspx",
        },
        {
          country: "Romania",
          label: "Autoritatea de Supraveghere Financiara (ASF)",
          link: "https://www.asfromania.ro/en/",
        },
        {
          country: "Slovakia",
          label: "Národná Banka Slovenska (NBS)",
          link: "https://subjekty.nbs.sk/entity/3167/",
        },
        {
          country: "Slovenia",
          label: "Agencija za trg vrednostnih papirjev (ATVP)",
          link: "https://www.a-tvp.si/eng",
        },
        {
          country: "Spain",
          label: "Comisión Nacional del Mercado de Valores (CNMV) - 3174",
          link: "https://www.cnmv.es/Portal/Consultas/ESI/ESISExtranjerasLP.aspx?tipo=CLP&numero=3174",
        },
        {
          country: "Sweden",
          label: "Finansinspektionen (FI) - 40728",
          link: "https://www.fi.se/en/our-registers/company-register/details?id=150846",
        },
      ],
    };
  },
};
</script>
